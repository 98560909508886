import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import Cookies from 'js-cookie';

export const Context = React.createContext();

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {

    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const [mainName, setMainName] = useState(null);

  // Created in an attempt to solve the bug where user name stays as anonymous even when logged in
  useEffect(() => {
    const checkCookie = () => {
      const nameFromCookie = Cookies.get('name');
      if (nameFromCookie) {
        setMainName(nameFromCookie);
      }
    };

    checkCookie();

    if (!mainName) {
      const intervalId = setInterval(() => {
        checkCookie();
        if (Cookies.get('name')) {
          clearInterval(intervalId);
        }
      }, 100);

      return () => clearInterval(intervalId);
    }
  }, []);

  return (
    <RootStyle>
      {/* <DashboardNavbar onOpenSidebar={() => setOpen(true)} /> */}
      <Context.Provider value={[mainName, setMainName]}>
        <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} mainName={mainName} />
        <MainStyle>
          <Outlet />
        </MainStyle>
      </Context.Provider>
    </RootStyle>
  );
}
