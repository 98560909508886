import { alpha, Avatar, Box, Button, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography, useTheme } from "@mui/material";
import BackButton from "../backButton";
import { useEffect, useState } from "react";
import { APICreateDriver, APIUpdateDriver } from "src/utils/apiCalled";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import MuiPhoneNumber from "material-ui-phone-number";
import { getImageFirebase } from "src/utils/getImageFirebase";
import DragAndDropBase64 from "../DragAndDropBase64";

export default function PageDriver({ driver, setOpen, setSuccMsg, setErrMsg, setVariant, setMainMsg, setModalTriggerAPICalled, handleCloseMoreDrivers }) {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [status, setStatus] = useState('');
    const [nricNo, setNricNo] = useState('');
    const [nricImage, setNricImage] = useState('');
    const [driverImage, setDriverImage] = useState('');

    const [loading, setLoading] = useState(false);
    const [posLoading, setPosLoading] = useState(false);
    const [driverData, setDriverData] = useState({});
    const [disabledButton, setDisabledButton] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [passwordMatch, setPasswordMatch] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const [phoneError, setPhoneError] = useState(false);
    const [phoneErrorMessage, setPhoneErrorMessage] = useState('');
    const [driverImg, setDriverImg] = useState(null);
    const [nricImg, setNricImg] = useState(null);

    useEffect(() => {
        if (driver) {
            setFirstName(driver.firstName || '');
            setLastName(driver.lastName || '');
            setEmail(driver.email || '');
            setPhone(driver.phone || '');
            setPassword(driver.password || '');
            setConfirmPassword(driver.password || '');
            setStatus(driver.status || '');
            setNricNo(driver.nricNo || '');
        }
    }, [driver])

    useEffect(() => {
        // Create the base driver data object
        const driverData = {
            firstName,
            lastName,
            email,
            phone,
            password,
            confirmPassword,
            status,
            nricNo,
        };

        // Conditionally add nricImage and driverImage if they are not empty strings
        if (nricImage !== "") {
            driverData.nricImage = nricImage;
        }
        if (driverImage !== "") {
            driverData.driverImage = driverImage;
        }

        setDriverData(driverData);
    }, [
        firstName,
        lastName,
        email,
        phone,
        password,
        confirmPassword,
        status,
        nricNo,
        nricImage, // Make sure to include this as a dependency
        driverImage, // Make sure to include this as a dependency
    ]);


    const theme = useTheme();

    const fontHeaderStyle = {
        color: theme.palette.grey[700],
        fontWeight: 'fontWeightMedium',
        mr: 5,
        minWidth: '250px'
    }

    const formControlStyle = {
        mb: 3,
        display: 'flex',
        flexDirection: 'row',

    }

    const buttonStyle = {
        border: '1px solid',
        borderColor: 'grey.300',
        px: 2,
        pt: 1,
        pb: 1,
        mr: 2,
        color: theme.palette.grey[700],
        backgroundColor: 'background.paper',
        '&:hover': {
            bgcolor: alpha(theme.palette.grey[500], 0.08),
        }
    };

    const confirmButtonStyle = {
        border: 'none',
        px: 2,
        pt: 1,
        pb: 1,
        mr: 2,
        bgcolor: theme.palette.primary.main,
        '&:hover': {
            bgcolor: alpha(theme.palette.primary.main, 0.90)
        },
        color: 'white',
        fontWeight: 'fontWeightMedium',
        '&.Mui-disabled': {
            bgcolor: theme.palette.primary.main,
            color: 'white',
            opacity: '0.6'
        },
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case 'firstName':
                setFirstName(value);
                break;
            case 'lastName':
                setLastName(value);
                break;
            case 'email':
                setEmail(value);

                const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
                if (!emailRegex.test(value)) {
                    setEmailError(true);
                    setEmailErrorMessage('Please enter a valid email address.');
                } else {
                    setEmailError(false);
                    setEmailErrorMessage('');
                }
                break;
            case 'phone':
                setPhone(value);
                break;
            case 'password':
                setPassword(value);
                break;
            case 'confirmPassword':
                setConfirmPassword(value);
                break;
            case 'status':
                setStatus(value);
                break;
            case 'nricNo':
                setNricNo(value);
                break;
            case 'nricImage':
                setNricImage(value);
                break;
            case 'driverImage':
                setDriverImage(value);
                break;
            default:
                break;
        }
    };

    const removeAllState = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhone('');
        setPassword('');
        setConfirmPassword('');
        setNricNo('');
        setStatus('');
        setNricImage('');
        setDriverImage('');
    };

    const apiAddEditCalled = async (value, id) => {
        try {
            if (driver && driver._id) {
                // console.log('UPDATE DRIVER')
                const result = await APIUpdateDriver(setPosLoading, id, value);
                if (result.status === 200) {
                    setPosLoading(false);
                    removeAllState();
                    setOpen(true);
                    setVariant('success');
                    setSuccMsg('Driver ' + value.firstName + ' ' + value.lastName + ' has sucessfully been edited');
                    setMainMsg('Driver Successfully Edited');
                    setModalTriggerAPICalled(true);
                } else {
                    setPosLoading(false);
                    removeAllState();
                    const errorResponse = result.response.data;
                    let errorMessage = 'Failed to create driver';
                    if (errorResponse && errorResponse.message === "In use") {
                        errorMessage = "This driver's email is already in use";
                    } if (errorResponse && errorResponse.message === 'Is invalid') {
                        errorMessage = "Email is invalid"
                    }

                    setOpen(true);
                    setVariant('error');
                    setErrMsg(errorMessage); // Set the error message
                    setMainMsg('Driver Submission Unsuccessful');
                    setModalTriggerAPICalled(true);
                }
            } else {
                const result = await APICreateDriver(setPosLoading, value);
                // console.log('CREATE DRIVER')
                if (result.status === 200) {
                    setPosLoading(false);
                    removeAllState();
                    setOpen(true);
                    setVariant('success');
                    setSuccMsg('Driver ' + value.firstName + ' ' + value.lastName + ' has sucessfully been created');
                    setMainMsg('Driver Successfully Created');
                    setModalTriggerAPICalled(true);
                } else {
                    setPosLoading(false);
                    removeAllState();
                    const errorResponse = result.response.data;
                    let errorMessage = 'Failed to create driver';
                    if (errorResponse && errorResponse.message === "In use") {
                        errorMessage = "This driver's email is already in use";
                    } if (errorResponse && errorResponse.message === 'Is invalid') {
                        errorMessage = "Email is invalid"
                    }

                    setOpen(true);
                    setVariant('error');
                    setErrMsg(errorMessage); // Set the error message
                    setMainMsg('Driver Submission Unsuccessful');
                    setModalTriggerAPICalled(true);
                }
            }
        } catch (err) {
            setPosLoading(false);
            removeAllState();
            setOpen(true);
            setVariant('error');
            setErrMsg(`Error! ${err.message || 'Please try again'}`);
        }
    };

    const handleSubmit = () => {
        if (disabledButton) {
            setOpen(true);
            setVariant('error');
            setErrMsg('Please fill all required fields');
            return;
        }

        apiAddEditCalled(driverData, driver ? driver._id : null);
        handleCloseMoreDrivers();
    };

    useEffect(() => {
        // Determine if the driver is valid (assuming driver is either an object or boolean)
        const isDriverValid = driver; // This can be a boolean or the presence of the 'driver' object
    
        // Check if all required fields are filled out
        const isFormFilled =
            firstName && lastName && email && phone && password &&
            confirmPassword && status && nricNo && // These must always be filled
            // If the driver is valid, we only require the NRIC and driver images
            (isDriverValid ? true : (nricImage && driverImage)) &&
            password === confirmPassword; // Check if password and confirmPassword match
    
        // Check if the form is unchanged (i.e., the values have not been modified)
        const isFormUnchanged =
            driver &&
            firstName === driver.firstName &&
            lastName === driver.lastName &&
            email === driver.email &&
            phone === driver.phone &&
            password === driver.password &&
            confirmPassword === driver.confirmPassword &&
            status === driver.status &&
            nricNo === driver.nricNo &&
            nricImage === driver.nricImage &&
            driverImage === driver.driverImage;
    
        // Set button disabled status: disabled if form is not filled or if form is unchanged
        setDisabledButton(!isFormFilled || isFormUnchanged);
    
    }, [
        firstName,
        lastName,
        email,
        phone,
        password,
        confirmPassword,
        status,
        nricNo,
        nricImage,
        driverImage,
        driver, // The 'driver' object determines if the driver is valid
    ]);
    



    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    }

    useEffect(() => {
        if (password && confirmPassword && password !== confirmPassword) {
            setPasswordMatch('Passwords currently do not match');
        } else {
            setPasswordMatch('');
        }
    }, [password, confirmPassword]);

    const handlePhoneChange = (value) => {
        setPhone(value);

        const digits = value.replace(/\D/g, '');
        if (digits.length < 8 || digits.length > 12) {
            setPhoneError(true);
            setPhoneErrorMessage('Phone number must be between 8 to 12 digits.');
        } else {
            setPhoneError(false);
            setPhoneErrorMessage('');
        }
    };

    const getImageDriver = (location, setState) => {
        getImageFirebase(location)
            .then((img) => {
                setState(img);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    useEffect(() => {
        if (driver) {
            // Fetch and set the driver image
            getImageDriver(driver.driverImageURI, setDriverImg);

            // Fetch and set the NRIC image
            getImageDriver(driver.nricImageURI, setNricImg);
        }
    }, [driver]);

    const handleDriverFileUpload = (base64Data) => {
        setDriverImage(base64Data);
        setDriverImg(base64Data);
    };

    const handleNricFileUpload = (base64Data) => {
        setNricImage(base64Data);
        setNricImg(base64Data);
    };


    return (
        <>
            <Box sx={{ mt: 2 }}></Box>
            <BackButton back={handleCloseMoreDrivers} type={'driver'} />
            <Box sx={{ mt: 2, pb: 4, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box>
                    <Typography variant="h4">{driver ? 'Edit Driver' : 'Create Driver'}</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                    <Button sx={buttonStyle} onClick={() => handleCloseMoreDrivers()}>
                        <Typography sx={{ fontWeight: 'fontWeightMedium' }}>
                            Cancel
                        </Typography>
                    </Button>
                    <Button sx={confirmButtonStyle} disabled={disabledButton} onClick={() => handleSubmit(driverData)}>
                        <Typography sx={{ fontWeight: 'fontWeightMedium' }}>
                            Confirm
                        </Typography>
                    </Button>
                </Box>
            </Box>

            <Box sx={{ padding: 1, borderTop: '1px solid', borderColor: theme.palette.grey[300], display: 'flex' }}>
                <Box sx={{ width: '70%', mt: 2 }}>
                    <FormControl sx={formControlStyle}>
                        <Typography sx={fontHeaderStyle}>
                            First Name <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <TextField label='First Name' name="firstName" value={firstName} onChange={handleInputChange} sx={{ flexGrow: 1 }} />
                    </FormControl>
                    <FormControl sx={formControlStyle}>
                        <Typography sx={fontHeaderStyle}>
                            Last Name <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <TextField label='Last Name' name="lastName" value={lastName} onChange={handleInputChange} sx={{ flexGrow: 1 }} />
                    </FormControl>
                    <FormControl sx={formControlStyle}>
                        <Typography sx={fontHeaderStyle}>
                            Nric No <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <TextField label='NRIC No' name="nricNo" value={nricNo} onChange={handleInputChange} sx={{ flexGrow: 1 }} />
                    </FormControl>
                    <FormControl sx={formControlStyle}>
                        <Typography sx={fontHeaderStyle}>
                            Email <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <TextField disabled={!!driver} label='Email' name="email" value={email} onChange={handleInputChange} sx={{ flexGrow: 1 }} error={emailError} helperText={emailError ? emailErrorMessage : ''} />
                    </FormControl>
                    <FormControl sx={formControlStyle}>
                        <Typography sx={fontHeaderStyle}>
                            Phone Number <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        {driver ? (
                            <TextField disabled={!!driver} label='Phone Number' name="phone" value={phone} onChange={handleInputChange} sx={{ flexGrow: 1 }} />
                        ) : (
                            <MuiPhoneNumber
                                label="Phone Number"
                                disabled={!!driver}
                                autoFormat={false}
                                variant="outlined"
                                name="phone"
                                defaultCountry="my" // Set default country as needed
                                value={phone}
                                onChange={handlePhoneChange} // Phone input change handler
                                sx={{ flexGrow: 1 }}
                                error={phoneError}  // Show error style if phone is invalid
                                helperText={phoneError ? phoneErrorMessage : ''}  // Show error message
                            />
                        )}
                    </FormControl>
                    <FormControl sx={formControlStyle}>
                        <Typography sx={fontHeaderStyle}>
                            Password <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <TextField
                            label='Password'
                            type={showPassword ? 'text' : 'password'}
                            name="password"
                            onChange={handleInputChange}
                            sx={{ flexGrow: 1 }}
                            value={password}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleClickShowPassword} edge="end">
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormControl>
                    <FormControl sx={formControlStyle}>
                        <Typography sx={fontHeaderStyle}>
                            Confirm Password <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <TextField
                            label='Confirm Password'
                            type={showConfirmPassword ? 'text' : 'password'}
                            name="confirmPassword"
                            onChange={handleInputChange}
                            sx={{ flexGrow: 1 }}
                            value={confirmPassword}
                            error={!!passwordMatch}
                            helperText={passwordMatch}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleClickShowConfirmPassword} edge="end">
                                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormControl>
                    <Box sx={formControlStyle}>
                        <Typography sx={fontHeaderStyle}>
                            Status <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <FormControl fullWidth>
                            <InputLabel>Status</InputLabel>
                            <Select
                                label="Status"
                                name="status"
                                value={status}
                                onChange={handleInputChange}
                                sx={{ flexGrow: 1 }}
                            >
                                <MenuItem value="ACTIVE">Active</MenuItem>
                                <MenuItem value="INACTIVE">Inactive</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <FormControl sx={formControlStyle}>
                        <Typography sx={fontHeaderStyle}>
                            Driver Image <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '75%', alignItems: 'center' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <a
                                    href={driverImg}
                                    rel="noopener norefferer"
                                    target="_blank"
                                >
                                    <Avatar src={driverImg} sx={{
                                        height: 150,
                                        width: 150,
                                        display: 'flex',
                                        alignItems: 'center',
                                        border: '3px solid',
                                        borderColor: theme.palette.grey[200]
                                    }}
                                    />
                                </a>
                            </Box>
                            <DragAndDropBase64 onFileUpload={handleDriverFileUpload} setOpen={setOpen} setVariant={setVariant} setErrMsg={setErrMsg} setMainMsg={setMainMsg} />
                        </Box>
                    </FormControl>
                    <FormControl sx={formControlStyle}>
                        <Typography sx={fontHeaderStyle}>
                            Driver E-KYC Image <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '75%', alignItems: 'center' }}>
                            <a
                                href={nricImg}
                                rel="noopener norefferer"
                                target="_blank"
                            >
                                <Avatar src={nricImg} sx={{
                                    height: 180,
                                    width: 310,
                                    borderRadius: '5%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    border: '3px solid',
                                    borderColor: theme.palette.grey[200]
                                }}
                                />
                            </a>
                            <DragAndDropBase64 onFileUpload={handleNricFileUpload} />
                        </Box>
                    </FormControl>
                </Box>
            </Box>
        </>
    )
}