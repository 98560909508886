import { useContext, useEffect, useState } from "react";
import BackButton from "../backButton";
import { alpha, Box, Button, FormControl, TextField, Typography, useTheme } from "@mui/material";
import { APICreateUser, APIUpdateUser } from "src/utils/apiCalled";
import MuiPhoneNumber from "material-ui-phone-number";
import { Context } from "src/layouts/dashboard";
import Cookies from "js-cookie";

export default function PageUser({ handleCloseMoreUsers, user, setOpen, setSuccMsg, setErrMsg, setVariant, setMainMsg, setModalTriggerAPICalled }) {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [firebaseUUID, setFirebaseUUID] = useState('');
    const [loading, setLoading] = useState(false);
    const [posLoading, setPosLoading] = useState(false);
    const [userData, setUserData] = useState({});
    const [disabledButton, setDisabledButton] = useState(true);
    const [emailError, setEmailError] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const [phoneError, setPhoneError] = useState(false);
    const [phoneErrorMessage, setPhoneErrorMessage] = useState('');
    const [formChanged, setFormChanged] = useState(false);

    const [mainName, setMainName] = useContext(Context)

    useEffect(() => {
        if (user) {
            setFirstName(user.firstName || '');
            setLastName(user.lastName || '');
            setEmail(user.email || '');
            setPhone(user.phone || '');
            setFirebaseUUID(user.firebaseUUID || '');
        }
    }, [user])

    useEffect(() => {
        const sanitizedPhone = phone.startsWith('+') ? phone.replace('+', '') : phone;

        const userData = {
            firstName,
            lastName,
            firebaseUUID,
            ...(user ? {} : { email, phone: sanitizedPhone }) // Include email and phone only if user is undefined
        };

        setUserData(userData);
    }, [
        firstName,
        lastName,
        email,
        phone,
        firebaseUUID,
        user // Add user to dependencies to track changes
    ]);


    const theme = useTheme();

    const fontHeaderStyle = {
        color: theme.palette.grey[700],
        fontWeight: 'fontWeightMedium',
        mr: 5,
        minWidth: '250px'
    }

    const formControlStyle = {
        mb: 3,
        display: 'flex',
        flexDirection: 'row',

    }

    const buttonStyle = {
        border: '1px solid',
        borderColor: 'grey.300',
        px: 2,
        pt: 1,
        pb: 1,
        mr: 2,
        color: theme.palette.grey[700],
        backgroundColor: 'background.paper',
        '&:hover': {
            bgcolor: alpha(theme.palette.grey[500], 0.08),
        }
    };

    const confirmButtonStyle = {
        border: 'none',
        px: 2,
        pt: 1,
        pb: 1,
        mr: 2,
        bgcolor: theme.palette.primary.main,
        '&:hover': {
            bgcolor: alpha(theme.palette.primary.main, 0.90)
        },
        color: 'white',
        fontWeight: 'fontWeightMedium',
        '&.Mui-disabled': {
            bgcolor: theme.palette.primary.main,
            color: 'white',
            opacity: '0.6',
        },
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormChanged(true);
        switch (name) {
            case 'firstName':
                setFirstName(value);
                break;
            case 'lastName':
                setLastName(value);
                break;
            case 'email':
                setEmail(value);

                const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
                if (!emailRegex.test(value)) {
                    setEmailError(true);
                    setEmailErrorMessage('Please enter a valid email address.');
                } else {
                    setEmailError(false);
                    setEmailErrorMessage('');
                }
                break;
            case 'phone':
                setPhone(value);
                break;
            case 'firebaseUUID':
                setFirebaseUUID(value);
                break;
            default:
                break;
        }
    };

    const removeAllState = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhone('');
        setFirebaseUUID('');
    };

    const apiAddEditCalled = async (value, id) => {
        try {
            if (user && user._id) {
                const result = await APIUpdateUser(setPosLoading, id, value);
                if (result.status === 200) {
                    setPosLoading(false);
                    removeAllState();
                    setOpen(true);
                    setVariant('success');
                    setSuccMsg('User ' + value.firstName + ' ' + value.lastName + ' has sucessfully been edited');
                    setMainMsg('User Successfully Updated');
                    removeAllState();
                    setModalTriggerAPICalled(true);
                } else {
                    setPosLoading(false);
                    removeAllState();
                    setOpen(true);
                    setVariant('error')
                    setErrMsg('Failed to update user')
                    setMainMsg('User Submission Unsuccessful');
                    setModalTriggerAPICalled(true);
                }
            } else {
                const result = await APICreateUser(setPosLoading, value);
                if (result.status === 200) {
                    setPosLoading(false);
                    removeAllState();
                    setOpen(true);
                    setVariant('success');
                    setMainMsg('User Successfully Created');
                    setSuccMsg('User ' + value.firstName + ' ' + value.lastName + ' has sucessfully been created');
                    setModalTriggerAPICalled(true);
                } else {
                    setPosLoading(false);
                    removeAllState();
                    setOpen(true);
                    setVariant('error')
                    setErrMsg('Failed to create user')
                    setMainMsg('User Submission Unsuccessful');
                    setModalTriggerAPICalled(true);
                }
            }
        } catch (err) {
            setPosLoading(false);
            removeAllState();
            setOpen(true);
            setVariant('error');
            setErrMsg(`Error! ${err.message || 'Please try again'}`);
            setMainMsg('Error Encountered')
        }
    }

    const handleSubmit = () => {
        if (disabledButton) {
            setOpen(true);
            setVariant('error');
            setErrMsg('Please fill all required fields');
            return;
        }

        apiAddEditCalled(userData, user ? user._id : null);
        if (user.email === Cookies.get('email')) {
            setMainName(`${userData.firstName} ${userData.lastName}`);
        }
        handleCloseMoreUsers();
    };

    useEffect(() => {
        const isFormFilled = firstName && lastName && email && phone && firebaseUUID;
    
        const isFormUnchanged =
            user &&
            firstName === user.firstName &&
            lastName === user.lastName &&
            email === user.email &&
            phone === user.phone &&
            firebaseUUID === user.firebaseUUID;
    
        // Disable the button if the form is either not filled or if the data has not changed
        setDisabledButton(!isFormFilled || isFormUnchanged || !formChanged);
    }, [
        firstName,
        lastName,
        email,
        phone,
        firebaseUUID,
        formChanged,
        user, // Add user to dependencies to track changes
    ]);
    

    const handlePhoneChange = (value) => {
        setPhone(value);

        const digits = value.replace(/\D/g, '');
        if (digits.length < 8 || digits.length > 12) {
            setPhoneError(true);
            setPhoneErrorMessage('Phone number must be between 8 to 12 digits.');
        } else {
            setPhoneError(false);
            setPhoneErrorMessage('');
        }
    };

    return (
        <>
            <Box sx={{ mt: 2 }}></Box>
            <BackButton back={handleCloseMoreUsers} type={'user'} />
            <Box sx={{ mt: 2, pb: 4, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box>
                    <Typography variant="h4">{user ? 'Edit Admin User' : 'Create Admin User'}</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                    <Button sx={buttonStyle} onClick={() => handleCloseMoreUsers()}>
                        <Typography sx={{ fontWeight: 'fontWeightMedium' }}>
                            Cancel
                        </Typography>
                    </Button>
                    <Button sx={confirmButtonStyle} disabled={disabledButton} onClick={() => handleSubmit(userData)}>
                        <Typography sx={{ fontWeight: 'fontWeightMedium' }}>
                            Confirm
                        </Typography>
                    </Button>
                </Box>
            </Box>

            <Box sx={{ padding: 1, borderTop: '1px solid', borderColor: theme.palette.grey[300], display: 'flex' }}>
                <Box sx={{ width: '70%', mt: 2 }}>
                    <FormControl sx={formControlStyle}>
                        <Typography sx={fontHeaderStyle}>
                            First Name <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <TextField label="First Name" name="firstName" value={firstName} onChange={handleInputChange} sx={{ flexGrow: 1 }} />
                    </FormControl>
                    <FormControl sx={formControlStyle}>
                        <Typography sx={fontHeaderStyle}>
                            Last Name <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <TextField label="Last Name" name="lastName" value={lastName} onChange={handleInputChange} sx={{ flexGrow: 1 }} />
                    </FormControl>
                    <FormControl sx={formControlStyle}>
                        <Typography sx={fontHeaderStyle}>
                            Email <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <TextField disabled={!!user} label="Email" name="email" value={email} onChange={handleInputChange} sx={{ flexGrow: 1 }} error={emailError} helperText={emailError ? emailErrorMessage : ''} />
                    </FormControl>
                    <FormControl sx={formControlStyle}>
                        <Typography sx={fontHeaderStyle}>
                            Phone Number <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        {user ? (
                            <TextField disabled={!!user} label="Phone Number" name="phone" value={phone} onChange={handleInputChange} sx={{ flexGrow: 1 }} />
                        ) : (
                            <MuiPhoneNumber
                                label="Phone Number"
                                disabled={!!user}
                                autoFormat={false}
                                variant="outlined"
                                name="phone"
                                defaultCountry="my" // Set default country as needed
                                value={phone}
                                onChange={handlePhoneChange} // Phone input change handler
                                sx={{ flexGrow: 1 }}
                                error={phoneError}  // Show error style if phone is invalid
                                helperText={phoneError ? phoneErrorMessage : ''}  // Show error message
                            />
                        )}
                    </FormControl>
                    <FormControl sx={formControlStyle}>
                        <Typography sx={fontHeaderStyle}>
                            Firebase UUID <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <TextField label="Firebase UUID" name="firebaseUUID" value={firebaseUUID} onChange={handleInputChange} sx={{ flexGrow: 1 }} />
                    </FormControl>
                </Box>
            </Box>
        </>
    )
}