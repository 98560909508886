import React from 'react';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';

// components
import {
  Box,
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  useTheme
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';
import SnackBar from '../components/Snackbar';
import { APIGetUsers } from '../utils/apiCalled';
import CustomPagination from 'src/components/CustomPagination';
import PageVerification from 'src/components/dashboard/PageVerification';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_verify) => _verify.fullName.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);

}

const TABLE_HEAD = [
  { id: 'fullName', label: 'Full Name', alignRight: false },
  { id: 'nationality', label: 'Nationality', alignRight: false },
  { id: 'rejectionReason', label: 'Deep face remarks', alignRight: false },
];

const tableStyle = {
  border: '0.063rem solid #EAECF0',
  borderTop: '0.094rem solid #EAECF0',
  borderBottom: '0.094rem solid #EAECF0',
  boxShadow: '0rem 0.063rem 0.188rem rgba(16, 24, 40, 0.1), 0rem 0.063rem 0.125rem rgba(16, 24, 40, 0.06)',
  borderRadius: '0.75rem',
};

const loadingStyle = {
  position: 'absolute',
  zIndex: 10,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};


export default function VerificationModule() {

  const [page, setPage] = useState(0);
  const [modalEdit, setModalEdit] = useState(false);
  const [editState, setEditState] = useState(false);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openFilterPopUp, setOpenFilterPopUp] = useState(null); // filter popup
  // const [searchTimeout, setSearchTimeout] = useState(null);// buffer when do search
  // this to store the data when click one of the row
  const [detailsPage, setDetailsPage] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [variant, setVariant] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [succMsg, setSuccMsg] = useState(''); // this state for snackbar success message
  const [mainMsg, setMainMsg] = useState('');
  const [getUser, setGetUser] = useState([])
  const [modalTriggerAPICalled, setModalTriggerAPICalled] = useState(false)

  const [openPageVerification, setOpenPageVerification] = useState(false);
  const [selectedUser, setSelectedUser] = useState(false);

  const theme = useTheme();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // apicalled for getUser (verification)
  const getAPICalled = async () => {
    try {
      const statusParam = 'MANUAL';
      const result = await APIGetUsers(setLoading, statusParam);
      if (result.status === 200) {
        const { data } = result;
        setGetUser(data);
        setLoading(false);
      } else {
        setLoading(false);
        setOpen(true);
        setVariant('error');
        setMainMsg('Error Encountered')
        setErrMsg('Error!, Please try again');
      }
    } catch (err) {
      setLoading(false);
      setOpen(true);
      setMainMsg('Error Encountered');
      setErrMsg('Error!', err);
    }
  };

  useEffect(() => {
    getAPICalled();
  }, [])

  // this after click reject or verify will called back the getUser API
  useEffect(() => {
    if (modalTriggerAPICalled) {
      getAPICalled();
      setModalTriggerAPICalled(false)
    }
  }, [modalTriggerAPICalled])

  // this useEffect for call the searchFullName API called. Interval for 0.5 sec
  // useEffect(() => {
  //     if (searchTimeout) {
  //         clearTimeout(searchTimeout); // this to clear time out
  //     }
  //     setSearchTimeout( // to buffer the search by 0.5 second
  //         setTimeout(() => {
  //             getAPICalled(); // call the api
  //         }, 500),
  //     );
  //     return () => clearTimeout(searchTimeout); // this also to clear time out
  // }, [filterName]);

  // const getImageDriver = (location, setState) => {
  //     // console.log('go heree heelloowwww driverImage------------>>>>>>>>');
  //     getImageFirebase(location).then((imgs) => {
  //       setState(imgs);
  //     }).catch((error) => {
  //       console.log(error);
  //     })
  // }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    setPage(0);
  };

  // snackbarClose
  const handleSnackBarClose = () => {
    setOpen(false);
  };


  const handleEditOpen = async (value) => {
    // console.log('value', value);
    setModalEdit(true);
    setDetailsPage(value);
    setEditState(true);
  };

  const handleEditCLose = () => {
    setModalEdit(false);
    setEditState(false);
  };

  const handleTogglePageVerification = (user) => {
    setOpenPageVerification(!openPageVerification);
    setSelectedUser(user)
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - getUser.length) : 0;

  const filteredUsers = applySortFilter(getUser, getComparator(order, orderBy), filterName);


  return (
    <Page title="E-KYC Verification">
      <Container maxWidth="xl" sx={{ mt: !openPageVerification ? 12 : 0 }}>
        <SnackBar
          open={open}
          alert={variant}
          handleClose={handleSnackBarClose}
          errorMsg={errMsg}
          successMsg={succMsg}
          mainMsg={mainMsg}
        />
        {!openPageVerification && (
          <>
            <Box sx={{ pb: 5, display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
              <Typography variant="h3">E-KYC Verification</Typography>
              <Typography variant='body1' sx={{ color: theme.palette.grey[700] }}>Manually approve or reject E-KYC verifications</Typography>
            </Box>
            {loading && <CircularProgress color='success' sx={loadingStyle} />}
            <Box sx={tableStyle}>
              <UserListToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
                popupfilter={setOpenFilterPopUp}
                labelName="Search Full Name..."
                displayFilter={false}
              />
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={getUser.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                      {filteredUsers
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                          const { _id, fullName, nationality, rejectionReason } = row;
                          const isItemSelected = selected.indexOf(_id) !== -1;
                          return (
                            <TableRow
                              style={{ height: 60 }}
                              hover
                              key={_id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell align="left" component="th" scope="row" onClick={() => handleTogglePageVerification(row)}>
                                <Stack direction="row" alignItems="center" spacing={2}>
                                  <Typography variant='body2' sx={{ color: theme.palette.grey[900] }}>
                                    {fullName}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell align="left" onClick={() => handleTogglePageVerification(row)}><Typography variant='body2' sx={{ color: theme.palette.grey[600] }}>{nationality}</Typography></TableCell>
                              <TableCell align="left" onClick={() => handleTogglePageVerification(row)}><Typography variant='body2' sx={{ color: theme.palette.grey[600] }}>{rejectionReason}</Typography></TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 55 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
              <CustomPagination page={page} totalNum={filteredUsers.length} rowsPerPage={rowsPerPage} handleChangePage={handleChangePage} />
            </Box>
          </>
        )}
        {openPageVerification && <PageVerification
          handleClosePageVerification={handleTogglePageVerification}
          user={selectedUser}
          setOpen={setOpen}
          setSuccMsg={setSuccMsg}
          setErrMsg={setErrMsg}
          setVariant={setVariant}
          setMainMsg={setMainMsg}
          setModalTriggerAPICalled={setModalTriggerAPICalled}
        />}
      </Container>
    </Page>
  )
}
